import React, { useState } from "react";
import './ClientData.scss';

export function ClientData(props) {
  const { firstName, lastName, yourAddress, phone, email, pocztowy, informacja } = props;

  const mask = (event) => {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    let pos = event.target.selectionStart;
    if (pos < 4) event.preventDefault();
    let matrix = "+48 ___ ___ ___",
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, ""),
      new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a
      });
    i = new_value.indexOf("_");
    if (i !== -1) {
      i < 5 && (i = 4);
      new_value = new_value.slice(0, i);
    }

    let reg = matrix.substr(0, event.target.value.length).replace(/_+/g,
      function (a) {
        return "\\d{1," + a.length + "}"
      }).replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (!reg.test(event.target.value) || event.target.value.length < 5 || (keyCode > 47 && keyCode < 58)) {
      event.target.value = new_value;
      // Adjust cursor position if it ends up in a space
      while (event.target.value.charAt(pos) === ' ' && pos < new_value.length) {
        pos++;
      }

      event.target.selectionStart = event.target.selectionEnd = pos;
    }
    if (event.type === "blur" && event.target.value.length < 3) event.target.value = "";
  }


  const [error, setError] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    props.setField('phone', value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      setError(value.length !== 15);
    }, 600)); // задержка в 1 секунду
  };

  return (
    <div className="block-form__form__left__osobiste">
      <h5>Dane osobiste</h5>
      <div className="client-data-wrapp">
        <label className="client-data-label">
          <div>Imię <span>(wymagane)</span></div>
          <input type="text" name="firstname" value={firstName} onChange={event => props.setField('firstName', event.target.value)} placeholder="Imię" />
        </label>
        <label className="client-data-label">
          <div>Nazwisko <span>(opcjonalnie)</span></div>
          <input type="text" name='lastname' value={lastName} onChange={event => props.setField('lastName', event.target.value)} placeholder="Nazwisko" />
        </label>
      </div>
      <div className="client-data-wrapp">
        <label className="client-data-label">
          <div>Email <span>(wymagane)</span></div>
          <input type="email" name='email' value={email} onChange={event => props.setField('email', event.target.value)} placeholder="me@contakt.pl" />
        </label>
        <label className="client-data-label">
          <div>Telefon <span>(wymagane)</span></div>
          <input type="tel" name='phone'
            value={phone}
            // onChange={event => props.setField('phone', event.target.value)}
            onChange={handlePhoneChange}
            placeholder="+48 ___ ___ ___"
            onInput={mask}
            onFocus={mask}
            onBlur={mask}
            onKeyDown={mask}
          />
          <span className="error-message">{error ? 'Numer telefonu jest nieprawidłowy' : ''}</span>
        </label>
      </div>
      <div className="client-data-wrapp-address">
        <label className="client-data-label address">
          <div>Adres <span>(wymagane)</span></div>
          <input type="text" name='adress' value={yourAddress} onChange={event => props.setField('yourAddress', event.target.value)} placeholder="Ulica, nr. Domu i Mieszkania" />
        </label>
        <label className="client-data-label code">
          <div>Kod pocztowy <span>(opcjonalnie)</span></div>
          <input pattern="[0-9\-]*" type="text" name='kod_index' value={pocztowy}
            // onChange={event => props.setField('pocztowy', event.target.value)}
            onChange={event => {
              const inputValue = event.target.value;
              const filteredValue = inputValue.replace(/[^0-9-]/g, ''); // Фильтрация символов, оставляя только цифры и "-"
              props.setField('pocztowy', filteredValue);
            }}
            placeholder="__-___" />
        </label>
      </div>
      <label className="client-data-label">
        <div>Dodatkowe pole informacji <span>(opcjonalnie)</span></div>
        <textarea name="notes" value={informacja} onChange={event => props.setField('informacja', event.target.value)} placeholder="Text" />
      </label>
    </div>
  )
}