import React, { useState } from 'react';
import "./FileInput.scss";

export const FileInput = ({ fileName, setFileName, name }) => {
    const [inputKey, setInputKey] = useState(Date.now())
    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const fileName = files[0].name;
            const extension = fileName.split('.').pop();
            const maxLength = 32;
            const truncatedName = fileName.slice(0, maxLength - extension.length - 1);
            if (fileName.length <= maxLength) {
                setFileName(fileName);
            } else {
                setFileName(`${truncatedName}...${extension}`);
            }
        } else {
            setFileName('');
        }
    };

    const handleFileDelete = () => {
        setFileName('');
        setInputKey(Date.now())
    };

    return (
        <div className="input__wrapper">
            <input
                key={inputKey}
                name={name}
                type="file"
                id="input__file"
                className="input input__file"
                onChange={handleFileChange}
            />
            <label htmlFor="input__file" className="input__file-button">
                <span className="input__file-icon-wrapper">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8M14 2L20 8M14 2V8H20M12 18V12M9 15H15" stroke="#4280BB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                {fileName ? (
                    <span className="input__file-button-text">{fileName}</span>
                ) : (
                    // <span className="input__file-button-text">dodaj załącznik <span>(wymagane)</span></span>
                    <span className="input__file-button-text">dodaj załącznik</span>
                )}
            </label>
            {fileName &&
                <button className='input__file-button-delete' onClick={handleFileDelete}>x</button>
            }
        </div>
    );
}